import Rails from "@rails/ujs"
// import * as bootstrap from "bootstrap"
// import "prismjs"
// import * as ActiveStorage from "@rails/activestorage"
import "bootstrap/js/dist/collapse"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/modal"
import "bootstrap/js/dist/popover"

// Make bootstrap globally available
// window.bootstrap = bootstrap;

Rails.start()
// ActiveStorage.start()
// document.addEventListener("turbolinks:load", () => {
//   Prism.highlightAll();
//   //AOS.init();
// });
// document.addEventListener("turbo:load", () => {
//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl)
//   })

//   var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
//   var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
//     return new bootstrap.Popover(popoverTriggerEl)
//   })

//   var dropdownTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
//   var dropdownList = dropdownTriggerList.map(function (dropdownTriggerEl) {
//     return new bootstrap.Dropdown(dropdownTriggerEl)
//   })
// })
